<template>
  <div class="sidebar">
    <div
        v-if="isPanelOpen"
        @click="$emit('close')"
        class="sidebar-backdrop"
    >
    </div>
    <transition
        name="slide"
    >
      <div
          v-if="isPanelOpen"
          class="sidebar-panel"
      >
        <validation-observer v-slot="{ invalid }">
            <b-row>
            <b-col cols="5"><span class="text-danger"><i class="h4 icofont icofont-close pointer" @click.stop="$emit('close')"> </i></span></b-col>
            <b-col><h4 class="text-primary">{{ attrs !== null ? attrs.title : ' ' }}</h4></b-col>
            <b-col class="text-center">
              <div>
                <button :disabled="invalid" @click="$emit('submit')" class="btn btn-success btn-sm validate-btn-padding">{{ $t('general.actions.validate') }}</button>
              </div>
            </b-col>
          </b-row>
            <slot name="body"></slot>
        </validation-observer>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    navOpen: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    attrs: {
      type: Object,
      default: null,
    }
  },
  computed: {
    isPanelOpen() {
      return this.navOpen;
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #FFFFff;
  position: fixed;
  right: 0;
  top: 84px;
  height: 100vh;
  z-index: 99999;
  padding: 1rem 20px 2rem 20px;
  width: calc(100% - 205px);
}
</style>
