<template>
  <div class="topnav">
    <template v-for="(tab, i) of tabs">
      <a
          :key="'body-tab-' + i"
          :class="tab.active ? 'active ' + position : ' ' + position"
          class="pointer"
          @click="changeTab(tab)"
      >
        {{ $t(tab.name) }}
      </a>
    </template>
    <a
        v-if="position === 'float-right'"
        class="float-left"
        :class="titleClass"
    >
      {{ $t(title) }}
    </a>
  </div>
</template>
<script>
export default {
  props: {
    updatedTab: {
      type: Boolean,
      default: false,
    },
    titleClass:{
      type: String,
      default: 'topnav-title'
    },
    position: {
      type: String,
      default: 'float-left'
    },
    hasInputs: {
      type: Boolean,
      default: true
    },
    parent: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: [],
    }
  },
  watch: {
    updatedTab: function () {
      this.loadActiveTabs();
    }
  },
  mounted () {
    this.loadActiveTabs ();
  },
  methods: {
    loadActiveTabs () {
      let selectedTab = null;
      try {
       selectedTab = JSON.parse(localStorage.getItem(this.parent + '-selectedTab'));
      } catch (e) {

      }
      if (selectedTab !== null ) {
        for(let tab of this.tabs) {
          tab.active = selectedTab === tab.name;
        }
      }
    },
    changeTab(selectedTab) {
      let oldSelectedTab = this.tabs.find((selected) => selected.active === true);

      oldSelectedTab.active = false;
      selectedTab.active = true;

      this.$emit('tab-changed', selectedTab);
    }
  }
}
</script>

<style scoped>
.topnav {
  margin-left: 0px;
  background-color: transparent;
  overflow: hidden;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.topnav a {
  display: block;
  color: #707070;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
  font: normal normal 900 18px Avenir;
  border-bottom: 3px solid transparent;
}

.topnav .topnav-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  padding: 10px 10px;
  color: #435179;
  opacity: 1;
}

.topnav .topnav-title-red-light {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  padding: 10px 10px;
  color: #FD8787;
  opacity: 1;
}

.topnav a:hover {
  color: #00A5D8;
}

.topnav .topnav-title:hover {
  color: #435179;
}

.topnav .topnav-title-red-light:hover {
  color: #FD8787;
}

.topnav a.active {
  color: #00A5D8;
  border-bottom: 3px solid #00A5D8;
  z-index: 9999;
}
</style>
