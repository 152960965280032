<template>
  <date-picker
      v-model="model"
      v-bind="{ ...$attrs }"
      :inline="true"
      :lang="lang"
      @change="$emit('on:calendar-change', model)"
    />
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    disabledDatesOperator: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    lang: {
      formatLocale:{
        weekdaysMin: [],
      }
    },
  }),
  methods: {
    hideModal () {
      this.$bvModal.hide('calendar-modal-id');
    }
  },
  created () {
    this.lang.formatLocale.weekdaysMin = [
      this.$t('general.actions.shortWeekDays.monday'),
      this.$t('general.actions.shortWeekDays.tuesday'),
      this.$t('general.actions.shortWeekDays.wednesday'),
      this.$t('general.actions.shortWeekDays.thursday'),
      this.$t('general.actions.shortWeekDays.friday'),
      this.$t('general.actions.shortWeekDays.saturday'),
      this.$t('general.actions.shortWeekDays.sunday'),
    ];
  }
};
</script>

<style scoped>
/deep/ .mx-calendar-content .cell.active {
  color: #fff;
  background-color: #206D84;
  border-radius: 3px;
  padding-top: 0px;
}
</style>
