<template>
  <b-modal
      :id="identifier"
      hide-header
      hide-footer
      hide-header-close
      body-bg-variant="gray-lighten"
      size="sm"
  >
    <date-picker
        v-if="filterFromDate === null && filterAfterDate === null"
        v-model="model"
        v-bind="{ ...$attrs }"
        :inline="true"
        :lang="lang"
        @change="$emit('on:calendar-change', model)"
    />
    <date-picker
        v-else
        v-model="model"
        v-bind="{ ...$attrs }"
        :inline="true"
        :lang="lang"
        :disabled-date="filterFromDate ? (date) => date < filterFromDate : filterAfterDate ?  (date) => date > filterAfterDate : null"
        @change="$emit('on:calendar-change', model)"
    />

    <b-row>
      <b-col align="center">
        <d-button
            :text="'general.actions.dismiss'"
            class="d-btn-ssm mt-3 btn d-btn-danger font-text-title"
            size="sm"
            @on:button-click="hideModal()"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: {
    model: {
      type: Object,
      default: () => {
      },
    },
    filterFromDate: {
      type: Object,
      default: null,
    },
    identifier: {
      type: String,
      default: 'calendar-modal-id',
    },
    filterAfterDate: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    lang: {
      formatLocale: {
        weekdaysMin: [],
      }
    },
  }),
  watch: {
    show: function (changes) {
      this.$bvModal.show(this.identifier);
    },
    hide: function (changes) {
      this.hideModal();
    },
  },

  methods: {
    hideModal() {
      this.$bvModal.hide(this.identifier);
    },
  },
  created() {
    this.lang.formatLocale.weekdaysMin = [
      this.$t('general.actions.shortWeekDays.sunday'),
      this.$t('general.actions.shortWeekDays.monday'),
      this.$t('general.actions.shortWeekDays.tuesday'),
      this.$t('general.actions.shortWeekDays.wednesday'),
      this.$t('general.actions.shortWeekDays.thursday'),
      this.$t('general.actions.shortWeekDays.friday'),
      this.$t('general.actions.shortWeekDays.saturday'),
    ];
  }
}
</script>
<style scoped>
.modal-title-class {
  text-align: center;
  font: normal normal 500 25px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}

/deep/ .modal {
  padding-top: 88px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

.stripe-element {
  border: 1px solid #ced4da;
  border-radius: 2px;
  padding: 3px;
}

/deep/ .mx-calendar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 6px 12px;
  align-items: center;
}

/deep/ .mx-calendar-content .cell.active {
  color: #fff;
  background-color: #206D84;
  border-radius: 3px;
  padding-top: 0px;
}
</style>
