<template>
  <dropdown
      style="background-color: #F7FAFC;    padding: 0px;"
      :name="name"
      :style="cssCustomStyle"
  >
    <template v-for="(menu, i) of menus">
      <div class="text-left pointer menu-style">
        {{ $t(menu) }}
      </div>
      <div
         v-if="parseInt(i) < menus.length - 1"
         class="border-bottom-grey"
      />
    </template>
  </dropdown>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: 'menu'
      },
      top: {
        type: String,
        default: ''
      },
      width: {
        type: String,
        default: ''
      },
      left: {
        type: String,
        default: ''
      },
      right: {
        type: String,
        default: ''
      },
      menus: {
        type: Array,
        default: () => [],
      }
    },
    computed: {
      cssCustomStyle () {
        return 'width:' + this.width + ';top:' + this.top + ';' + 'left:' + this.left + ';' + 'right:' + this.right + ';';
      }
    }
  }
</script>
<style scoped>
.vue-dropdown.dropdown-position-bottom:before {
  border-bottom: 10px solid #F7F9F9;
  top: -10px;
  display: none;
  filter: drop-shadow(0 -2px 2px rgba(52, 73, 94, 0.1));
}

.menu-style:hover {
  background-color: #e5e5e5;
}

.menu-style {
  padding: 5px;
  padding-left: 18px;
  height: 30px;
}
</style>
