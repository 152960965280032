<template>
  <v-select
    v-model="search"
    v-bind="{ ...$attrs }"
    :options="options"
    :disabled="isDisabled"
    :filter="fuseSearch"
    :class="className"
  >
    <template #search="{ attributes, events }">
      <i
        class="position-absolute add-participant-class"
        :class="icon"
        :style="iconStyle"
      ></i>
      <input
        v-model="filter"
        v-bind="attributes"
        v-on="events"
        :placeholder="getPlaceHolder"
        class="vs__search padding-icon-input"
      >
    </template>
    <template #no-options="{ search, searching, loading }">
      {{ $t('general.actions.no-result') }}
    </template>
  </v-select>
</template>
<script>
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    VSelect: () => import(/* webpackChunkName: "Planning" */'vue-select'),
  },
  data: () => ({
    filter: '',
    search: '',
  }),
  props: {
    placeHolder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: '#0c5460'
    },
    className: {
      type: String,
      default: 'background-light-blue-inputs'
    },
    icon: {
      type: String,
      default: 'fa fa-user-plus'
    },
    options: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    iconStyle() {
      return {
        '--icon-color': this.iconColor
      }
    },
    getPlaceHolder() {
      return (this.search === '' || this.search === null) ? this.$t(this.placeHolder) : '';
    },
  },
  watch: {
    filter: function () {
      this.$emit('on:keydown', this.filter)
    },
    search: function () {
      this.filter = '';
      this.$emit('on:selected', this.search);
      this.search = '';
    }
  },
  methods: {
    fuseSearch() {
      return this.options;
    }
  }
}
</script>
<style lang="scss" scoped>
.add-participant-class {
  margin-top: 1.9pt;
  font-size: 20px;
  z-index: 1;
  color: var(--icon-color);
}

/deep/ .vs__dropdown-toggle {
  background: #EAF2F7;
  padding: 2px;
}

/deep/ .vs__selected {
  padding-left: 26px;
}

.vs__search::placeholder {
  color: #80888F;
  opacity: 1;
  font-size: 14.8px;
  margin: auto;
}

.vs__search.padding-icon-input {
  padding-left: 28px;
}

.col-7-5 {
  flex: 0 0 65.333333%;
  max-width: 68.333333%;
}

/deep/ .vs__dropdown-menu li {
  background-color: unset;
  color: #1b1e21;
}

/deep/ .vs__dropdown-menu li:hover {
  background-color: #E3E9EF;
  color: #1b1e21;
}

/deep/ .vs__search, .vs__search:focus {
  margin: 2.6px 0 0;
  z-index: 0;
}

</style>
