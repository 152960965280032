<template>
  <dropdown-menu
      v-model="display"
      v-bind="{...$props, ...$attrs}"
  >
    <button :id="identifier" hidden></button>
    <slot name="dropdownbutton">
    </slot>
    <div slot="dropdown">
      <slot name="dropdownbody">
      </slot>
    </div>
  </dropdown-menu>
</template>
<script>
export default {
  data: () => ({
    active: '',
    identifier: '',
    key: 0,
    display: false,
    renderComponent: true,
  }),
  props: {
    hide: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    identifier: {
      type: String,
      default: 'default',
    },
  },
  components: {
    DropdownMenu: () => import ('@innologica/vue-dropdown-menu')
  },
  watch: {
    show: function (val) {
      document.getElementById(this.identifier).click();
    },
  },
};
</script>

<style scoped>
.outlined-none:focus {
  outline: 0;
}

/deep/ .dropdown-menu {
  display: block;
  background-color: #F8FAFA ;
  min-width: 13em;
  padding: 0.3rem;
}
</style>
