<template>
  <validation-provider
      v-slot="{ errors, ariaInput }"
      :name="$t(label)"
      :rules="rules"
  >
      <b-form-select
          v-model="model"
          v-bind="{ ...$attrs, ...ariaInput }"
          :type="type"
          :min="min"
          :max="max"
          :class="className"
          v-if="model === null || model === ''"
          :options="options"
      >
      </b-form-select>
      <template v-else>
        <b-form-select
            v-if="rules"
            v-model="model"
            v-bind="{ ...$attrs, ...ariaInput }"
            :type="type"
            :min="min"
            :class="className"
            :max="max"
            @change="$emit('on:select-option:change', model)"
            :options="options"
            :state="errors.length === 0"
        />
        <b-form-select
            v-else
            v-model="model"
            v-bind="{ ...$attrs, ...ariaInput }"
            :type="type"
            :min="min"
            :class="className"
            :max="max"
            @change="$emit('on:select-option:change', model)"
            :options="options"
        />
      </template>


      <b-form-invalid-feedback
          :state="errors.length === 0"
          v-if="model !== ''"
      >
        {{ $t(errorText) }}
      </b-form-invalid-feedback>
  </validation-provider>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: [],
    },
    className: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    errorText: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: this,
    },
    max: {
      type: Number,
      default: this,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    model: {
      get () { return this.value; },
      set (model) { this.$emit('input', model); },
    },
  },
  methods: {
    getLabel (ariaInput) {
      let label = this.$t(this.label);

      if (undefined !== ariaInput['aria-required'] && ariaInput['aria-required'] === 'true') {
        label += '*';
      }

      return label;
    },
  },
};
</script>
