<template>
  <div>
    <vs-radio
        class="pointer"
        @change.stop="onClick"
        color="#00A5D8"
        :vs-value="false"
        v-model="row.singleSelection"
    >
      {{ text }}
    </vs-radio>
  </div>
</template>
<script>
export default {
  data: () => ({
    radio: 'light',
  }),
  props: {
    text: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: this,
    }
  },
  methods: {
    onClick() {
      this.$emit('on:radio-button-check', this.row);
    }
  }
}
</script>
<style>
.radio-style {
  border: 2px solid #F1F4F7;
}
.vs-radio--circle {
  border: #0c5460;
}
</style>
