<template>
  <div
      class="container-fluid"
      :class="fullBody ? 'fullBodyTopbar': 'customBodyTopBar'"
  >
    <b-card
        class="pb-0"
    >
      <b-card-text>
        <div v-if="!isIconsContent" class="row">
          <div class="col-md-7">
            <h6 class="title-top"> {{ title }}</h6>
            <div class="tree-structure-top">
              <slot name="tree"></slot>
            </div>
          </div>
          <div class="col-md-5 align-content-end">
            <slot name="icons"></slot>
          </div>
        </div>
        <div v-else>
          <div class="pull-left">
            <h6 class="title-top"> {{ title }}</h6>
            <div class="tree-structure-top">
              <slot name="tree"></slot>
            </div>
          </div>
          <div class="pull-right">
            <slot name="icons"></slot>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
  export default {
    props: {
      isIconsContent: {
        type: Boolean,
        default: true
      },
      fullBody: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: '',
      },
    }
  }
</script>
<style scoped>
  .title-top {
    text-align: left;
    font: normal normal 900 28px/21px Avenir;
    letter-spacing: 0.88px;
    color: #4D4F5C;
    opacity: 1;
    font-size: 25px
  }
  .tree-structure-top {
    text-align: left;
    font: normal normal 400 20px/21px Avenir;
    letter-spacing: 0.63px;
    font-size: 15px
  }
 .fullBodyTopbar .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 25px #52575D1A;
    border-radius: 8px;
    height: 94px;
    opacity: 1;
  }
  .customBodyTopBar .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 25px #52575D1A;
    border-radius: 8px;
    height: 80px;
    opacity: 1;
  }
</style>